:root {
  --bs-danger: tomato;
}

.done {
  width: 100px;
  text-align: center;
}

.name {
  width: 400px;
}

.poms {
  width: 100px;
}

.links {
  width: calc(100% - 25px);
}

.group {
  width: 100px;
}

.project {
  width: 200px;
}

.title {
  width: 30%;
}

.template-title {
  width: 250px;
}

.description {
  width: 65%;
}

.color {
  width: 5%;
  text-align: center;
}

.actions {
  width: 50px;
  text-align: center;
}

.editable-item {
  display: flex;
}

.button-group {
  align-self: flex-end;
}

.content {
  flex-grow: 1;
}

.main-todos .complete {
  text-decoration: line-through;
}

.vertical-align {
  vertical-align: middle;
}

.wide-modal {
  width: 1000px;
  max-width: 80% !important;
}

.wide-modal .modal-dialog {
  width: 100%;
  max-width: none !important;
  margin: 0;
}

.toast-container-wrapper {
  opacity: 1 !important;
  z-index: 10000;
  position: fixed;
  bottom: 50px;
}

.small-invisible-button {
  background-color: transparent;
  border: none !important;
  border-color: white;
  margin: 0;
  padding: 0;
}

.dropdown-item.disabled {
  pointer-events: all; /* allow hover */
}

.dropdown-submenu {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.smooth-mouse-menu {
  position: absolute;
  height: 20px;
  width: 100%;
}

.break-badge {
  background-color: #428ab8 !important;
}

.pomodoro-badge {
  background-color: tomato !important;
}

.stopped-badge {
  background-color: #777f81 !important;
}
